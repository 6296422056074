import { useClientEventTracking } from './useClientEventTracking';
import { sendEvent } from '../sendEvent';
import {
  ErrorEventType,
  ErrorWebEventTrackingObject,
  TrackingEvent,
  WebEventCategory,
} from '@Core/tracking/types';
import { createSearchTrackingObject } from '@Stores/searchStoreTracker';
import { useStoreContext } from '@Stores/StoreContext';

export interface UseNoAvailabilityTrackingProps {
  action: string;
}

/**
 * Reporting "No Availability" to both Rudderstack and Prometheus.
 */
export const useNoAvailabilityTracking = ({ action }: UseNoAvailabilityTrackingProps) => {
  const { searchAvailability, searchSelection } = useStoreContext();
  const sendClientEvent = useClientEventTracking();

  return () => {
    sendEvent<ErrorWebEventTrackingObject>({
      event: TrackingEvent.webEvent,
      category: WebEventCategory.error,
      action,
      errorType: ErrorEventType.noAvailability,
      ...createSearchTrackingObject(searchSelection.getState(), searchAvailability.getState()),
    });

    sendClientEvent({
      type: ErrorEventType.noAvailability,
      subType: action,
    });
  };
};
